
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,400&display=swap');

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: 'Poppins',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{
  scroll-behavior: smooth;
  height: 100%;
  overflow-x: hidden;
}
#root{
  width: 100%;
}

